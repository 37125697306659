import React, { useState } from "react";
import Recaptcha from "react-google-recaptcha";
import ContactHomeSection from "./ContactHomeSection";
export default function ContactForm() {
  const siteKey = "6Lf2234aAAAAABIBnsgj5tFm2TBujQQLFqTtsHRt";
  const secretKey = "6Lf2234aAAAAABbgk9O-uheGD84T30m7Njg_y5qO";
  const [formValue, setFormValue] = useState({});
  const recaptchaRef = React.createRef();
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };
  const handleSubmit = async e => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "g-recaptcha-response": recaptchaValue,
        ...formValue,
      }),
    })
      .then(e => console.log("success", e))
      .catch(error => alert(error));
  };
  const handleChange = e =>
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  return (
    <ContactHomeSection>
      <form
        action="POST"
        name="contact"
        className="contact__form"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <h3 className="contact__form__title">
          Vous souhaitez en savoir plus? On s'occupe de tout.
        </h3>
        <div className="contact__form__warper">
          <input
            placeholder="Votre nom"
            type="text"
            name="name"
            id="name"
            className="contact__form__input"
            onChange={handleChange}
          />
          <input
            placeholder="Votre téléphone"
            type="tel"
            inputMode="tel"
            name="phone_no"
            id="phone-no"
            className="contact__form__input"
            onChange={handleChange}
          />
          <input
            placeholder="Votre addresse email"
            type="email"
            inputMode="email"
            name="email"
            id="email"
            className="contact__form__input"
            onChange={handleChange}
          />
          <label className="contact__form__terms">
            <input type="checkbox" id="check" />
            En soumettant ce formulaire, j'accepte que les informations saisies
            soient exploitées par <strong>TheOffice</strong> dans le but de
            répondre à ma demande et dans le cadre de la relation commerciale
            qui pourrait en découler.
          </label>
          <Recaptcha
            ref={recaptchaRef}
            sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
            size="normal"
            id="recaptcha-google"
          />
          <input type="submit" value="Envoyer" className="contact__form__btn" />
        </div>
      </form>
    </ContactHomeSection>
  );
}
